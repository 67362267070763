import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import MediaService from "../../../services/mediaService";
import { CONFIG } from "../../../configs/config";

import LoadingSpinner from "../../../components/UI/LoadingSpinner";
import ramonaIcon from "../../../assets/logo/icon/green/logo.svg";
import AddIcon from "@material-ui/icons/Add";

interface Props {
	hit: any;
}

const GalleryItem: React.FC<Props> = ({ hit }) => {
	const [thumbnail, setThumbnail] = useState(ramonaIcon);
	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);

	const { objectID, baseUrl, displayTitle, description, tileSources } = hit;
	// The first image tile at the 9th layer of the pyramid is typically around 400 x 300 px
	const thumbnailUrl = tileSources[0].replace(".dzi", "_files/9/0_0.jpeg");
	const isDeepZoomImage = tileSources.length > 0;

	useEffect(() => {
		hit.public
			? setThumbnail(baseUrl + thumbnailUrl)
			: getSignedThumbnail();
	}, [thumbnailUrl, baseUrl, hit.public]);

	const getSignedThumbnail = () => {
		setIsLoading(true);
		return new MediaService()
			.getSignedUrl(thumbnailUrl, CONFIG.S3_BUCKET_PRIVATE)
			.catch(() => {
				setHasError(true);
			})
			.then(result => {
				result && setThumbnail(result);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleBrokenImageUrl = (e: any) => {
		setHasError(true);
		e.target.src = ramonaIcon;
	};

	return isDeepZoomImage ? (
		<Link
			to={{
				pathname: `/gallery/viewer/${objectID}`,
				state: {
					fromGallery: true
				}
			}}
			key={objectID}
			className={`gallery__item ${hasError && "placeholder-image"}`}
		>
			{isLoading ? (
				<>
					<LoadingSpinner />
				</>
			) : (
				<>
					<img
						className="thumbnail"
						src={thumbnail}
						alt={displayTitle}
							onError={e => handleBrokenImageUrl(e)}
							loading="lazy"
					/>

					<div className="overlay">
						<div className="overlay__content">
							<h2 className="overlay__title">{displayTitle}</h2>

							<span className="overlay__icon icon--primary icon--outline--alt">
								<AddIcon />
							</span>

							<p className="overlay__description">
								{description}
							</p>
						</div>
					</div>
				</>
			)}
		</Link>
	) : null;
};

export default GalleryItem;
