export const CONFIG = {
  ENV: process.env.REACT_APP_ENV ?? "dev",
  REGION: "us-east-1",
  USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID ?? "us-east-1_bpmhQXxcs",
  APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID ?? "6r6jmjsfuoceinbgejmcg5luv9",
  IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID ?? "us-east-1:d55bb65d-2446-4533-b8cc-4c725fce7d80",
  IDENTITY_PROVIDER_NAME: process.env.REACT_APP_IDENTITY_PROVIDER_NAME ?? "cognito-idp.us-east-1.amazonaws.com/us-east-1_bpmhQXxcs",
  S3_BUCKET_PUBLIC: `ramonaopti-14037-${process.env.REACT_APP_ENV ?? "dev"}-public-data`,
  S3_BUCKET_PRIVATE: `ramonaopti-14037-${process.env.REACT_APP_ENV ?? "dev"}-private-data`,
  S3_API_VERSION: "2006-03-01",
  ALGOLIA_INDEX: process.env.REACT_APP_ALGOLIA_INDEX ?? "dev_ImageIndex",
  ALGOLIA_APP_ID: "JNI9HPGWFG",
  ALGOLIA_SEARCH_KEY: "e988ede2bf09c984b33aa2e62cf305c7"
};