import Tooltip from "./Tooltip";
import RemoveIcon from "@material-ui/icons/RemoveOutlined";
import AddIcon from "@material-ui/icons/AddOutlined";
import ResetTvIcon from "../../../assets/icons/ResetTvOutlined";
import ZoomInMapIcon from "../../../assets/icons/ZoomInMapOutlined";

interface Props {
	toggleSlowZoom: () => void;
	isSlowZoomMode: boolean;
}

const ZoomControlDock: React.FC<Props> = ({
	toggleSlowZoom,
	isSlowZoomMode
}) => {
	return (
		<>
			<Tooltip title="Zoom Out">
				<button
					className="icon-button icon--white"
					type="button"
					aria-label="zoom out"
					id="zoom-out"
				>
					<RemoveIcon />
				</button>
			</Tooltip>

			<Tooltip title="Zoom In">
				<button
					className="icon-button icon--white"
					type="button"
					aria-label="zoom in"
					id="zoom-in"
				>
					<AddIcon />
				</button>
			</Tooltip>

			<Tooltip title="Reset">
				<button
					className="icon-button icon--white"
					type="button"
					aria-label="default zoom"
					id="home"
				>
					<ResetTvIcon />
				</button>
			</Tooltip>

			<Tooltip title="Toggle Slow Zoom">
				<button
					className={`icon-button icon--white ${
						isSlowZoomMode ? "active" : ""
					}`}
					type="button"
					aria-label="max zoom in"
					id="max-zoom-in"
					onClick={toggleSlowZoom}
				>
					<ZoomInMapIcon />
				</button>
			</Tooltip>
		</>
	);
};

export default ZoomControlDock;
