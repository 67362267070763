import { Modal, Slider } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutline";
import LoadingSpinner from "../../../../components/UI/LoadingSpinner";

interface Props {
	modalClose: () => void;
	handlePrecacheSliderChange: (event: any, newValue: any) => void;
	startPrecache: () => void;
	cancelPrecache: () => void;
	open: boolean;
	cacheSliderValue: number;
	totalFrames: number;
	isCaching: boolean;
	isCachingComplete: boolean;
}

const PrecacheModal: React.FC<Props> = ({
	modalClose,
	handlePrecacheSliderChange,
	startPrecache,
	cancelPrecache,
	open,
	cacheSliderValue,
	totalFrames,
	isCaching,
	isCachingComplete
}) => {
	const marks = [
		{
			value: totalFrames,
			label: "All Images"
		}
	];

	const showCachingImages = () => {
		return (
			<>
				<h2 className="heading-small">Caching Images...</h2>
				<div className="spinner-container">
					<LoadingSpinner />
				</div>
				<button
					key="cancel-precache"
					className="button--primary button--outline"
					onClick={cancelPrecache}
				>
					Cancel
				</button>
			</>
		);
	};

	const showSuccess = () => {
		return (
			<>
				<h2 className="heading-small cache-complete">Images Cached</h2>
				<span className="success-container">
					<CheckCircleIcon />
				</span>
				<button
					key="close-modal"
					className="button--primary"
					onClick={modalClose}
				>
					Close
				</button>
			</>
		);
	};

	return (
		<Modal
			container={() => document.getElementById('viewer-container')}
			className="modal precache-modal"
			open={open}
			onClose={modalClose}
			aria-labelledby="precache-modal"
			aria-describedby="precache-modal-description"
		>
			<div className="modal__dialog">
				{isCaching ? (
					showCachingImages()
				) : isCachingComplete ? (
					showSuccess()
				) : (
					<>
						<h2 className="heading-small">Precache Images?</h2>
						<fieldset>
							<legend>
								For the best viewing experience, precache all
								images before beginning playback.
							</legend>

							<Slider
								className="progress-bar"
								aria-labelledby="discrete-slider"
								valueLabelDisplay="on"
								value={cacheSliderValue}
								step={1}
								onChange={handlePrecacheSliderChange}
								min={1}
								max={totalFrames}
								marks={marks}
							/>
						</fieldset>

						<button
							key="start-precache"
							className="button--primary"
							onClick={startPrecache}
						>
							Start Precaching
						</button>
					</>
				)}
			</div>
		</Modal>
	);
};

export default PrecacheModal;
