import CompareIcon from "@material-ui/icons/CompareOutlined";
import FlipIcon from "@material-ui/icons/FlipOutlined";
import FlipToFrontIcon from "@material-ui/icons/FlipToFrontOutlined";
import FlipToBackIcon from "@material-ui/icons/FlipToBackOutlined";

import Tooltip from "./Tooltip";

interface Props {
	slider: any;
	activeMode: any;
}

const SliderControlDock: React.FC<Props> = ({ slider, activeMode }) => {
	const showSliderSingle = (index: number) => {
		const single = index === 1 ? "right" : "left";
		if (activeMode !== single)
			slider.mode.singleImage.load(index);
	};

	const showSliderDouble = () => {
		if (activeMode !== "double")
			slider.mode.doubleImage.load();
	};

	const showSliderCurtain = () => {
		if (activeMode !== "curtain")
			slider.mode.curtain.init();
	};

	return (
		<>
			<div className="input-group">
				<Tooltip title="Slider">
					<button
						className={`icon-button icon--white ${
							activeMode === "curtain"
								? "active"
								: ""
						}`}
						onClick={showSliderCurtain}
					>
						<CompareIcon />
					</button>
				</Tooltip>

				<Tooltip title="Side By Side">
					<button
						className={`icon-button icon--white ${
							activeMode === "double" ? "active" : ""
						}`}
						onClick={showSliderDouble}
					>
						<FlipIcon />
					</button>
				</Tooltip>

				<Tooltip title="Left Image Only">
					<button
						className={`icon-button icon--white ${
							activeMode === "first" ? "active" : ""
						}`}
						onClick={() => showSliderSingle(0)}
					>
						<FlipToFrontIcon />
					</button>
				</Tooltip>

				<Tooltip title="Right Image Only">
					<button
						className={`icon-button icon--white ${
							activeMode === "right" ? "active" : ""
						}`}
						onClick={() => showSliderSingle(1)}
					>
						<FlipToBackIcon />
					</button>
				</Tooltip>
			</div>
		</>
	);
};

export default SliderControlDock;
