import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { Toast } from "../../../utils/notifications";

// Material UI
import Fade from "@material-ui/core/Fade";
import CodeIcon from "@material-ui/icons/Code";
import RotateRightIcon from "@material-ui/icons/RotateRightOutlined";
import OpenInFullIcon from "../../../assets/icons/OpenInFullOutlined";
import CloseFullscreenIcon from "../../../assets/icons/CloseFullscreenOutlined";

// OpenSeadragon Plugins
import OpenSeadragon from "../../../configs/abtOpenSeadragonPlugin";
import "@openseadragon-imaging/openseadragon-imaginghelper";
import "../plugins/bookmarkURL";

// Custom Components
import Tooltip from "./Tooltip";
import Scalebar from "./Scalebar";
import ZoomControlDock from "./ZoomControlDock";
import SequenceControlDock from "./SequenceControlDock";
import SliderControlDock from "./SliderControlDock";
import Metadata from "./Metadata";
import LoadingSpinner from "../../../components/UI/LoadingSpinner";

/**
 * This component takes in the relevant frames and initializes them to an OSD viewer
 * @param {*} param0
 */
const OpenSeadragonViewer = ({ imageData, tileSources }) => {
	const {
		displayTitle,
		description,
		additionalDescription,
		categories,
		imageDetails
	} = imageData;
	const { showColorBar, pixelsPerMeter, rotationDegree } =
		imageData.viewerOptions;
	const isSequenceMode = imageData.viewerOptions.imageMode === "sequence";
	const isSliderMode = imageData.viewerOptions.imageMode === "slider";

	let changedFrame = true;
	const [viewer, setViewer] = useState(null);
	const [viewerLoaded, setViewerLoaded] = useState(false);
	const [currentZoom, setCurrentZoom] = useState(0);
	const [isSlowZoomMode, setIsSlowZoomMode] = useState(false);
	const [isFullscreenMode, setIsFullscreenMode] = useState(false);
	const [isScalebarVisible, setIsScalebarVisible] = useState(false);

	// Slider
	const [sliderState, setSliderState] = useState({
		activeMode: "curtain",
		activeDrags: 0,
		staticDeltaX: 0,
		isNewDrag: false
	});

	const sliderRef = useRef({
		leftImage: null,
		rightImage: null,
		curtain: {
			position: 0,
			deltaX: 0,
			leftBound: 0,
			rightBound: 0,
			leftRect: null,
			rightRect: null,
			newRect: null
		}
	});

	const slider = {
		images: {
			leftImage: sliderRef.current.leftImage,
			rightImage: sliderRef.current.rightImage,
		},

		mode: {
			singleImage: {
				load: index => {
					viewer.world.removeAll();
			
					try {
						viewer.addTiledImage({
							tileSource: tileSources[index],
							x: 0,
							y: 0,
							opacity: 1,
							success: function (event) {
								slider.mode.curtain.images.leftImage = event.item;
								viewer.viewport.goHome();
							}
						});
					} catch {
						Toast("Error", "Unable to load image.", "danger");
					}
			
					index === 1
						? setSliderState({ ...sliderState, activeMode: "right" })
						: setSliderState({ ...sliderState, activeMode: "left" });
				}
			},
	
			doubleImage: {
				load: () => {
					viewer.world.removeAll();
			
					try {
						viewer.addTiledImage({
							tileSource: tileSources[0],
							x: 0,
							y: 0,
							opacity: 1,
							width: 0.5,
							success: function (event) {
								slider.mode.curtain.images.leftImage = event.item;
								viewer.viewport.goHome();
							}
						});
			
						viewer.addTiledImage({
							tileSource: tileSources[1],
							x: 0.5,
							y: 0,
							opacity: 1,
							width: 0.5,
							success: function (event) {
								slider.mode.curtain.images.rightImage = event.item;
								viewer.viewport.goHome();
							}
						});
					} catch {
						Toast("Error", "Unable to load images.", "danger");
					}
			
					setSliderState({ ...sliderState, activeMode: "double" });
				}
			},
	
			curtain: {
				specs: sliderRef.current.curtain,
	
				init: () => {
					sliderRef.current.curtain = {
						position: 0,
						deltaX: sliderState.staticDeltaX,
						leftBound: 0,
						rightBound: 0,
						leftRect: new OpenSeadragon.Rect(0, 0, 0, 0),
						rightRect: new OpenSeadragon.Rect(0, 0, 0, 0),
						newRect: new OpenSeadragon.Rect(0, 0, 0, 0)
					};
	
					slider.mode.curtain.images.load();
					setSliderState({ ...sliderState, activeMode: "curtain" });
				},
	
				images: {
					load: () => {
						viewer.world.removeAll();
		
						try {
							viewer.addTiledImage({
								tileSource: tileSources[0],
								success: function (event) {
									sliderRef.current.leftImage = event.item;
									slider.mode.curtain.images.adjust();
								}
							});
		
							viewer.addTiledImage({
								tileSource: tileSources[1],
								success: function (event) {
									sliderRef.current.rightImage = event.item;
									slider.mode.curtain.images.adjust();
								}
							});
						} catch {
							Toast("Error", "Unable to load images.", "danger");
						}
					},
		
					adjust: () => {
						if (sliderRef.current.leftImage && sliderRef.current.rightImage) {
							slider.mode.curtain.images.clip();
							slider.mode.curtain.setBounds();
							slider.mode.curtain.bindToDragHandle();
							viewer.viewport.goHome();
						}
					},
	
					clip: () => {
						const { curtain, leftImage, rightImage } = sliderRef.current;
						const viewport = viewer.viewport;
		
						curtain.leftRect.height = leftImage.getContentSize().y;
						curtain.rightRect.height = rightImage.getContentSize().y;
						curtain.deltaX = curtain.position;
		
						const midpoint = new OpenSeadragon.Point(
							viewport.containerSize.x / 2 + curtain.deltaX,
							viewport.containerSize.y / 2
						);
		
						slider.mode.curtain.images.clipShared(
							rightImage.viewerElementToImageCoordinates(midpoint).x,
							leftImage.viewerElementToImageCoordinates(midpoint).x
						);
					},
		
					clipAggressive: () => {
						const { curtain, leftImage, rightImage } = sliderRef.current;
						const viewport = viewer.viewport;
		
						const midpoint = new OpenSeadragon.Point(
							viewport.containerSize.x / 2 + curtain.deltaX,
							viewport.containerSize.y / 2
						);
		
						slider.mode.curtain.images.clipShared(
							rightImage.viewportToImageCoordinates(
								viewport.viewerElementToViewportCoordinates(midpoint)
							).x,
							leftImage.viewportToImageCoordinates(
								viewport.viewerElementToViewportCoordinates(midpoint)
							).x
						);
					},
					
					clipShared: (rightCoord, leftCoord) => {
						const { curtain, rightImage } = sliderRef.current;
						const { leftRect, rightRect } = curtain;
		
						rightRect.x = rightCoord;
						rightRect.width = rightImage.getContentSize().x - rightCoord;
						leftRect.width = leftCoord;
						rightImage.setClip(rightRect);
					}
				},
	
				setBounds: () => {
					const curtain = sliderRef.current.curtain;
					const midpoint = viewer.viewport.containerSize.x / 2;
					curtain.leftBound = midpoint * -1;
					curtain.rightBound = midpoint;
				},
	
				bindToDragHandle: () => {
					viewer.addHandler("animation", slider.mode.curtain.images.clipAggressive);
					viewer.addHandler("animation-start", slider.mode.curtain.images.clip);
				},
	
				handleDrag: (e, ui) => {
					const { curtain } = sliderRef.current;
	
					if (sliderState.isNewDrag) {
						curtain.deltaX = sliderState.staticDeltaX;
						setSliderState({
							...sliderState,
							activeDrags: 1,
							isNewDrag: false
						});
					}
	
					curtain.deltaX = ui ? ui.x : curtain.deltaX;
					const midpoint = new OpenSeadragon.Point(
						viewer.viewport.containerSize.x / 2 + curtain.deltaX,
						viewer.viewport.containerSize.y / 2
					);
	
					if (sliderRef.current.rightImage) {
						const leftCoord =
							sliderRef.current.rightImage.viewerElementToImageCoordinates(
								midpoint
							).x;
						const imageWidth = sliderRef.current.rightImage.getContentSize().x;
						const newWidth =
							leftCoord < 0 ? imageWidth - leftCoord : imageWidth;
						curtain.newRect = new OpenSeadragon.Rect(
							leftCoord,
							0,
							newWidth,
							18000
						);
						sliderRef.current.rightImage.setClip(curtain.newRect);
					}
				},
	
				handleStart: () => {
					sliderRef.current.curtain.deltaX = sliderState.staticDeltaX;
					setSliderState({
						...sliderState,
						activeDrags: 1,
						isNewDrag: true
					});
				},
	
				handleStop: () => {
					setSliderState({
						...sliderState,
						activeDrags: 0,
						staticDeltaX: sliderRef.current.curtain.deltaX,
						isNewDrag: true
					});
	
					sliderRef.current.curtain.position = sliderRef.current.curtain.deltaX;
				}
			}
		}
	};

	useEffect(() => {
		if (tileSources) {
			const tileCount = tileSources.length;
			if (tileCount > 0) {
				initOpenseadragon(tileSources);
			}

			return () => {
				viewer && viewer.destroy();
			};
		}
	}, [tileSources]);

	useEffect(() => {
		if (viewer) {
			// remove the inner keydown handler from OSD
			viewer.innerTracker.keyDownHandler = null;
			viewer.innerTracker.keyPressHandler = null;
			viewer.bookmarkUrl();
			viewer.addHandler("tile-drawn", () => setViewerLoaded(true));

			if (sliderState.activeDrags === 0 && viewer.activateImagingHelper) {
				viewer.activateImagingHelper({
					onImageViewChanged: handleImageViewChange
				});
			}
		}

		return () => {
			viewer && viewer.destroy();
		};
	}, [viewer]);

	useEffect(() => {
		// Reset viewport to the default rotation
		viewer && (viewer.viewport.degrees = rotationDegree);
	}, [sliderState.activeMode]);

	useEffect(() => {
		if (viewer && isSliderMode) {
			slider.mode.curtain.init();
		}
	}, [viewer, isSliderMode]);

	useEffect(() => {
		if (viewer) {
			setIsScalebarVisible(pixelsPerMeter > 0 && !isSlowZoomMode);

			function startSlowZoom() {
				const viewport = viewer.viewport;
				viewport.zoomSpring.animationTime = 10;
				viewport.zoomSpring.springStiffness = 1;

				// Zoom in or out depending on the current zoom level
				const maxZoom = viewport.getMaxZoom();
				currentZoom === maxZoom
					? viewport.goHome()
					: viewport.zoomTo(maxZoom);
			}

			function stopSlowZoom() {
				const viewport = viewer.viewport;
				viewport.zoomSpring.animationTime = 1.2;
				viewport.zoomSpring.springStiffness = 6.5;
				viewport.zoomTo(viewer.viewport.getZoom());
			}

			isSlowZoomMode ? startSlowZoom() : stopSlowZoom();
		}
	}, [viewer, pixelsPerMeter, isSlowZoomMode]);

	const toggleFullscreen = () => {
		isFullscreenMode
			? document.exitFullscreen()
			: document.getElementById("viewer-container").requestFullscreen();

		document.onfullscreenchange = () =>
			setIsFullscreenMode(document.fullscreenElement);
	};

	const handleImageViewChange = () => {
		const viewport = viewer.viewport;
		if (changedFrame) changedFrame = false;
		setCurrentZoom(viewport.getZoom());
	};

	const toggleSlowZoom = () => {
		setIsSlowZoomMode(!isSlowZoomMode);
	};

	const initOpenseadragon = tileSources => {
		viewer && viewer.destroy();

		if (isSliderMode) {
			// Slider images must wait until the viewer has been initialized
			tileSources = "";
			setCurrentZoom(1);
		} else if (isSequenceMode) {
			// Get all tile sources for sequence playback but only make the first one visible
			tileSources = tileSources.map(function (tileSource, i) {
				return {
					tileSource: tileSource,
					opacity: i === 0 ? 1 : 0,
					preload: i === 1
				};
			});
		} else {
			// Default to single image.
			// Make sure there is only one tile source,
			// otherwise the viewport zoom will be off.
			tileSources = tileSources[0];
		}

		setViewer(
			OpenSeadragon({
				tileSources: tileSources,
				id: "OpenSeadragon",
				maxZoomPixelRatio: 8,
				showNavigator: true,
				maxImageCacheCount: 4096,
				navigatorPosition: "ABSOLUTE",
				navigatorTop: "100px",
				navigatorLeft: "1rem",
				navigatorHeight: "150px",
				navigatorWidth: "225px",
				navigatorBorderColor: "#20CC4B",
				loadTilesWithAjax: true,
				preload: true,
				showSequenceControl: isSequenceMode,
				showRotationControl: true,
				degrees: rotationDegree,
				rotateRightButton: "rotate-right",
				zoomInButton: "zoom-in",
				zoomOutButton: "zoom-out",
				homeButton: "home",
				preserveViewport: true,
				crossOriginPolicy: false,
				ajaxWithCredentials: false,
				viewportMargins: {
					top: 100,
					bottom: 85
				}
			})
		);
	};

	return (
		<>
			<Fade
				in={viewerLoaded}
				timeout={1000}
				children={document.querySelector(".openseadragon-container")}
			>
				<div className="viewer" id="OpenSeadragon">
					<div className="viewer__overlay">
						<Metadata
							displayTitle={displayTitle}
							description={description}
							additionalDescription={additionalDescription}
							categories={categories}
							imageDetails={imageDetails}
							showColorBar={showColorBar}
						/>

						{isSliderMode && sliderState.activeMode === "curtain" && (
							<Draggable
								onDrag={slider.mode.curtain.handleDrag}
								onStart={slider.mode.curtain.handleStart}
								onStop={slider.mode.curtain.handleStop}
								axis="x"
								bounds={{
									left: slider.mode.curtain.leftBound,
									right: slider.mode.curtain.rightBound
								}}
							>
								<span className="curtain-handle">
									<CodeIcon className="curtain-handle__icon" />
								</span>
							</Draggable>
						)}

						<div className="viewer__bottombar">
							{isScalebarVisible && (
								<Scalebar
									ppm={
										// Divide PPM by 2 if there are 2 images side-by-side in the viewport at once
										isSliderMode &&
										sliderState.activeMode === "double"
											? pixelsPerMeter / 2
											: pixelsPerMeter
									}
									currentZoom={currentZoom}
									isFullscreenMode={isFullscreenMode}
								/>
							)}

							<div className="viewer__control-group zoom-controls">
								<ZoomControlDock
									toggleSlowZoom={toggleSlowZoom}
									isSlowZoomMode={isSlowZoomMode}
								/>
							</div>

							{isSequenceMode && (
								<div className="viewer__control-group sequence-controls">
									<SequenceControlDock
										viewer={viewer}
										changedFrame={changedFrame}
										tileSources={tileSources}
									/>
								</div>
							)}

							{isSliderMode && (
								<div className="viewer__control-group slider-controls">
									<SliderControlDock slider={slider} activeMode={sliderState.activeMode} />
								</div>
							)}

							<div className="viewer__control-group">
								<Tooltip title="Rotate Right">
									<button
										style={{
											visibility:
												isSliderMode &&
												sliderState.activeMode ===
													"curtain"
													? "hidden"
													: "visible"
										}}
										className="icon-button icon--white"
										type="button"
										aria-label="rotate right"
										id="rotate-right"
									>
										<RotateRightIcon />
									</button>
								</Tooltip>

								<Tooltip title="Toggle Full Screen">
									<button
										className="icon-button icon--white full-screen"
										type="button"
										aria-label="toggle full screen"
										onClick={toggleFullscreen}
									>
										{document.fullscreenEnabled &&
											(isFullscreenMode ? (
												<CloseFullscreenIcon />
											) : (
												<OpenInFullIcon />
											))}
									</button>
								</Tooltip>
							</div>
						</div>
					</div>
				</div>
			</Fade>

			{!viewerLoaded && <LoadingSpinner />}
		</>
	);
};

export default React.memo(OpenSeadragonViewer);
