import { Switch, Route, Redirect } from "react-router-dom";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import Amplify from "aws-amplify";
import { CONFIG } from "./configs/config";
import { pageNotFound } from "./utils/errors";

import Layout from "./components/layout/Layout";
import Login from "./pages/Login";
import Gallery from "./pages/Gallery/";
import Viewer from "./pages/Viewer/";

Amplify.configure({
	aws_cognito_region: CONFIG.REGION,
	aws_user_pools_id: CONFIG.USER_POOL_ID,
	aws_user_pools_web_client_id: CONFIG.APP_CLIENT_ID
});

const App = () => {
	return (
		<>
			<ReactNotification />
			<Layout>
				<Switch>
					<Route path="/" exact>
						<Redirect to="/gallery" />
					</Route>
					<Route path="/gallery" exact>
						<Gallery />
					</Route>
					<Route
						path={[
							"/gallery/viewer/:imageID",
							"/gallery/embed/:imageID"
						]}
						exact
					>
						<Viewer />
					</Route>
					<Route path="/signin">
						<Login />
					</Route>
					<Route>{() => pageNotFound()}</Route>
				</Switch>
			</Layout>
		</>
	);
};

export default App;
