import { CONFIG } from './config';
import MediaService from '../services/mediaService';
import Constants from '../common/constants';
var OpenSeaDragon = require('openseadragon/build/openseadragon/openseadragon');

const mediaService = new MediaService();


/**
 * Makes an AJAX request.
 * @param {Object} options
 * @param {String} options.url - the url to request
 * @param {Function} options.success - a function to call on a successful response
 * @param {Function} options.error - a function to call on when an error occurs
 * @param {Object} options.headers - headers to add to the AJAX request
 * @param {String} options.responseType - the response type of the the AJAX request
 * @param {Boolean} [options.withCredentials=false] - whether to set the XHR's withCredentials
 * @throws {Error}
 * @returns {XMLHttpRequest}
 */
async function AbtMakeAjaxRequest(url, onSuccess, onError) {
    var withCredentials;
    var headers;
    var responseType;

    var urlParam = url?.url;

    if (urlParam && urlParam.includes(CONFIG.S3_BUCKET_PRIVATE) && !urlParam.includes(".dzi") && !urlParam.includes(".html")) {
        var split = urlParam.split(`${CONFIG.S3_BUCKET_PRIVATE}.s3.amazonaws.com/`)[1];
        if (split) {
            var split2 = split.split('?')[0];
            if (split2) {
                var imageKey = decodeURI(split2);
                const signedUrl = await mediaService.getSignedUrl(imageKey, CONFIG.S3_BUCKET_PRIVATE);
                if (signedUrl) {
                    url.url = signedUrl;
                }
            }
        }
    }

    if (url?.url && !url.url.includes(CONFIG.S3_BUCKET_PRIVATE) && url.url.includes(Constants.ThumbnailKeySuffix) && url.url.includes("amazon")) {
        var seconds = Math.floor(Date.now() / 1000);
        if (url.url.includes("?")) {
            url.url += `&cacheBuster=${seconds}`;
        } else {
            url.url += `?cacheBuster=${seconds}`;
        }
    }

    // Note that our preferred API is that you pass in a single object; the named
    // arguments are for legacy support.
    if (OpenSeaDragon.isPlainObject(url)) {
        onSuccess = url.success;
        onError = url.error;
        withCredentials = url.withCredentials;
        headers = url.headers;
        responseType = url.responseType || null;
        url = url.url;
    }

    var protocol = OpenSeaDragon.getUrlProtocol(url);
    var request = OpenSeaDragon.createAjaxRequest(protocol === "file:");

    if (!OpenSeaDragon.isFunction(onSuccess)) {
        throw new Error("makeAjaxRequest requires a success callback");
    }

    request.onreadystatechange = function () {
        // 4 = DONE (https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest#Properties)
        if (request.readyState == 4) {
            request.onreadystatechange = function () { };

            // With protocols other than http/https, a successful request status is in
            // the 200's on Firefox and 0 on other browsers
            if ((request.status >= 200 && request.status < 300) ||
                (request.status === 0 &&
                    protocol !== "http:" &&
                    protocol !== "https:")) {
                onSuccess(request);
            } else {
                OpenSeaDragon.console.log("AJAX request returned %d: %s", request.status, url);

                if (OpenSeaDragon.isFunction(onError)) {
                    onError(request);
                }
            }
        }
    };

    try {
        request.open("GET", url, true);

        if (responseType) {
            request.responseType = responseType;
        }

        if (headers) {
            for (var headerName in headers) {
                if (Object.prototype.hasOwnProperty.call(headers, headerName) && headers[headerName]) {
                    request.setRequestHeader(headerName, headers[headerName]);
                }
            }
        }

        if (withCredentials) {
            request.withCredentials = true;
        }

        request.send(null);
    } catch (e) {
        var msg = e.message;

        /*
            IE < 10 does not support CORS and an XHR request to a different origin will fail as soon
            as send() is called. This is particularly easy to miss during development and appear in
            production if you use a CDN or domain sharding and the security policy is likely to break
            exception handlers since any attempt to access a property of the request object will
            raise an access denied TypeError inside the catch block.
 
            To be friendlier, we'll check for this specific error and add a documentation pointer
            to point developers in the right direction. We test the exception number because IE's
            error messages are localized.
        */
        var oldIE = OpenSeaDragon.Browser.vendor == OpenSeaDragon.BROWSERS.IE && OpenSeaDragon.Browser.version < 10;
        if (oldIE && typeof (e.number) != "undefined" && e.number == -2147024891) {
            msg += "\nSee http://msdn.microsoft.com/en-us/library/ms537505(v=vs.85).aspx#xdomain";
        }

        OpenSeaDragon.console.log("%s while making AJAX request: %s", e.name, msg);

        request.onreadystatechange = function () { };

        if (window.XDomainRequest) { // IE9 or IE8 might as well try to use XDomainRequest
            var xdr = new window.XDomainRequest();
            if (xdr) {
                xdr.onload = function (e) {
                    if (OpenSeaDragon.isFunction(onSuccess)) {
                        onSuccess({ // Faking an xhr object
                            responseText: xdr.responseText,
                            status: 200, // XDomainRequest doesn't support status codes, so we just fake one! :/
                            statusText: 'OK'
                        });
                    }
                };
                xdr.onerror = function (e) {
                    if (OpenSeaDragon.isFunction(onError)) {
                        onError({ // Faking an xhr object
                            responseText: xdr.responseText,
                            status: 444, // 444 No Response
                            statusText: 'An error happened. Due to an XDomainRequest deficiency we can not extract any information about this error. Upgrade your browser.'
                        });
                    }
                };
                try {
                    xdr.open('GET', url);
                    xdr.send();
                } catch (e2) {
                    if (OpenSeaDragon.isFunction(onError)) {
                        onError(request, e);
                    }
                }
            }
        } else {
            if (OpenSeaDragon.isFunction(onError)) {
                onError(request, e);
            }
        }
    }

    return request;
};

OpenSeaDragon.makeAjaxRequest = AbtMakeAjaxRequest;

export default OpenSeaDragon;