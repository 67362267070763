const Twitter = () => {
	return (
		<svg viewBox="0 0 24 24" fill="none">
			<path
				d="M23.8149 0.184136C23.6968 0.0656524 23.5371 -0.000577381 23.3708 3.79283e-06H6.4368C4.73435 -0.000241469 3.10018 0.677419 1.88755 1.88649C1.29117 2.48091 0.817266 3.18914 0.493263 3.97016C0.169261 4.75118 0.00160044 5.58948 0 6.43652V23.3712C0.000102707 23.454 0.01651 23.536 0.0482853 23.6124C0.0800606 23.6887 0.126567 23.7579 0.185065 23.8159C0.303157 23.9344 0.462892 24.0006 0.629196 24H17.5632C19.2657 24.0003 20.8998 23.3226 22.1124 22.1135C22.7088 21.5191 23.1827 20.8109 23.5067 20.0299C23.8307 19.2488 23.9984 18.4105 24 17.5635V0.628836C23.9999 0.545989 23.9835 0.463993 23.9517 0.387639C23.9199 0.311284 23.8734 0.242104 23.8149 0.184136ZM17.7405 9.80692C17.7498 9.92707 17.7498 10.0535 17.7498 10.1736C17.7498 13.9232 14.9277 18.2438 9.77396 18.2438C8.24828 18.2475 6.7539 17.8059 5.46991 16.9721C5.6942 16.9969 5.91974 17.0084 6.14535 17.0065C7.40646 17.0093 8.63186 16.5828 9.62438 15.7956C9.0394 15.7851 8.47232 15.5897 8.00251 15.2369C7.5327 14.8841 7.18368 14.3915 7.00431 13.828C7.42465 13.9019 7.85574 13.8844 8.26885 13.7765C7.63462 13.6456 7.06467 13.2967 6.6557 12.789C6.24673 12.2814 6.02392 11.6462 6.02506 10.9912V10.9569C6.41299 11.1764 6.84696 11.2995 7.29115 11.3158C6.90674 11.0568 6.59154 10.7057 6.37359 10.2938C6.15587 9.88197 6.04247 9.42197 6.04358 8.95495C6.04052 8.45215 6.17158 7.95783 6.42295 7.5241C7.12681 8.40073 8.00496 9.11783 9.00043 9.62884C9.9959 10.1399 11.0864 10.4334 12.2013 10.4904C12.1049 10.0735 12.103 9.63997 12.1956 9.22225C12.2882 8.80452 12.473 8.4134 12.7361 8.07813C12.9993 7.74287 13.3339 7.47215 13.715 7.28623C14.0961 7.1003 14.5139 7.00399 14.937 7.00449C15.3208 7.00345 15.7008 7.08243 16.0531 7.23653C16.4055 7.39063 16.7227 7.61653 16.9849 7.90014C17.6118 7.77848 18.213 7.54721 18.7614 7.2167C18.553 7.87127 18.1154 8.42619 17.5308 8.77707C18.0883 8.71307 18.6331 8.56475 19.147 8.33705C18.7651 8.90511 18.2893 9.40234 17.7405 9.80692Z"
				fill="#060F24"
			></path>
		</svg>
	);
};

export default Twitter;
