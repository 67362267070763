import React from "react";

import PrimaryLinks from "./PrimaryLinks";
import SecondaryLinks from "./SecondaryLinks";
import AuthNav from "./AuthNav";

const MainNav: React.FC = () => {
	return (
		<div className="nav__links">
			<ul className="nav__links__primary">
				<PrimaryLinks />
			</ul>

			<ul className="nav__links__secondary">
				<SecondaryLinks />

				<AuthNav />
			</ul>
		</div>
	);
};

export default MainNav;
