import React, { useCallback, useEffect, useRef } from "react";
import Tooltip from "../Tooltip";
import { getScalebarSizeAndTextForMetric } from "./helper";
import { useWindowWidth } from "@react-hook/window-size/";

interface Props {
	ppm: number;
	currentZoom: number;
	isFullscreenMode: boolean;
}

const defaultImagePixelWidth = 1200;
const minScalebarPixelWidth = 100;

const Scalebar: React.FC<Props> = ({ ppm, currentZoom, isFullscreenMode }) => {
	const scaleBarSpecs = useRef({ size: 0, text: "" });
	const resizeRatio = useRef(1.0);
	const windowWidth = useWindowWidth();
	const containerEl = document?.getElementById("viewer-container");

	const handleScalebarResize = useCallback(() => {
		resizeScalebar(currentZoom, ppm, containerEl);
	}, [currentZoom, ppm, containerEl]);

	useEffect(() => {
		handleScalebarResize();
	}, [handleScalebarResize, windowWidth, isFullscreenMode]);

	handleScalebarResize();

	function resizeScalebar(
		currentZoom: number,
		ppm: number,
		containerEl: HTMLElement | null
	) {
		const adjustedPPM = ppm / 20; // Adjust the PPM to compensate for 20x resolution discrepancy
		const containerWidth =
			containerEl?.offsetWidth ?? defaultImagePixelWidth;

		resizeRatio.current = containerWidth / defaultImagePixelWidth;
		scaleBarSpecs.current = getScalebarSizeAndTextForMetric(
			adjustedPPM * currentZoom * resizeRatio.current,
			minScalebarPixelWidth
		) ?? { size: 0, text: "" };
	}

	return (
		<Tooltip title="Scale" placement="bottom">
			<figure
				className="scalebar"
				style={{ width: scaleBarSpecs.current.size }}
				aria-label="scale bar"
			>
				<figcaption>{scaleBarSpecs.current.text}</figcaption>
			</figure>
		</Tooltip>
	);
};

export default Scalebar;
