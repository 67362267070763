import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import MainNav from "./MainNav";
import Sidebar from "../Sidebar";

import BottomCurve from "./BottomCurve";
import Logo from "./Logo";

const TopNavigation: React.FC = () => {
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const location = useLocation();

	useEffect(() => {
		setIsMobileMenuOpen(false);
	}, [location.pathname])

	return (
		<header
			className={`main-header ${isMobileMenuOpen && "sidebar--expanded"}`}
		>
			<nav className="nav">
				<a
					className="main-header__logo"
					aria-label="Homepage"
					href="https://www.ramonaoptics.com/"
				>
					<Logo />
				</a>

				<MainNav />

				<button
					className="hamburger-button"
					type="button"
					aria-label="Open navigation"
					onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
				>
					<span className="hamburger-button__icon"></span>
				</button>

				
				<Sidebar />
			</nav>
			<BottomCurve />
		</header>
	);
};

export default TopNavigation;
