import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Alert from "@material-ui/lab/Alert";

import MediaService from "../../services/mediaService";
import { CONFIG } from "../../configs/config";
import { imageIndex } from "../../configs/algolia";

import OpenSeadragonViewer from "./components/openSeadragonViewer";
import LoadingSpinner from "../../components/UI/LoadingSpinner";

const Viewer: React.FC = () => {
	const { imageID }: any = useParams();
	const [image, setImage]: any = useState(null);
	const [sources, setSources] = useState([]);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const cachedImage = sessionStorage.getItem(imageID);
		cachedImage
			? setImage(JSON.parse(cachedImage))
			: fetchImageFromAlgolia();
	}, [imageID]);

	useEffect(() => {
		if (!image) return;
		image.public
			? setSources(
					image.tileSources.map(
						(dziFilename: string) => image.baseUrl + dziFilename
					)
			  )
			: getSignedTileSources();
	}, [image]);

	const fetchImageFromAlgolia = () => {
		setLoading(true);

		imageIndex
			.search(imageID)
			.then(({ hits }) => {
				const objImage = getImage(hits);

				if (objImage) {
					setImage(objImage);
					// Cache the image data so we don't have to query Algolia for it again
					sessionStorage.setItem(imageID, JSON.stringify(objImage));
				} else {
					setError("Image not found.");
				}
			})
			.catch(() => {
				setError("Image not found.");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getImage = (data: any) => {
		const foundImage = data.length > 0;
		return foundImage ? data[0] : null;
	};

	const getSignedTileSources = () => {
		const signedSources: any = [];

		image.tileSources.map((url: any) =>
			new MediaService()
				.getSignedUrl(url, CONFIG.S3_BUCKET_PRIVATE)
				.catch(() => {
					console.log("Could not retrieve tile source(s).");
				})
				.then((result: any) => {
					signedSources.push(result);
					result && setSources(signedSources);
				})
		);
	};

	return (
		<div id="viewer-container" className="viewer-container">
			{loading && <LoadingSpinner />}

			{error && (
				<Alert severity="error" variant="filled">
					{error}
				</Alert>
			)}

			{image && (
				<OpenSeadragonViewer imageData={image} tileSources={sources} />
			)}
		</div>
	);
};

export default Viewer;
