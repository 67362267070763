import { SetStateAction, useEffect, useRef, useState } from "react";

// Custom Hooks
import { useKeyPress } from "../../../../hooks";

import GetAppIcon from "@material-ui/icons/GetAppOutlined";
import SkipNextIcon from "@material-ui/icons/SkipNextOutlined";
import SkipPreviousIcon from "@material-ui/icons/SkipPreviousOutlined";
import PlayArrowIcon from "@material-ui/icons/PlayArrowOutlined";
import PauseIcon from "@material-ui/icons/PauseOutlined";

import SpeedControl from "./SpeedControl";
import PrecacheModal from "./PrecacheModal";
import Tooltip from "../Tooltip";
import { Slider } from "@material-ui/core";

interface Props {
	viewer: any;
	changedFrame: boolean;
	tileSources: any;
}

const SequenceControlDock: React.FC<Props> = ({
	viewer,
	changedFrame,
	tileSources
}) => {
	const [index, setIndex] = useState(0);
	const [totalFrames, setTotalFrames] = useState(0);
	const [fps, setFPS] = useState(2);
	const [isPlaybackEnabled, setIsPlaybackEnabled] = useState(true);
	const [playbackIntervalId, setPlaybackIntervalId] = useState();
	const [progress, setProgress] = useState(0);
	const [commitSliderValue, setCommitSliderValue] = useState(0);
	const [cacheSliderValue, setCacheSliderValue] = useState(0);
	const [isCaching, setIsCaching] = useState(false);
	const [isCachingComplete, setIsCachingComplete] = useState(false);
	const currentFrame = useRef(0);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const leftPress = useKeyPress("ArrowLeft");
	const rightPress = useKeyPress("ArrowRight");

	useEffect(() => {
		if (tileSources) {
			const tileCount = tileSources.length;
			if (tileCount > 0) {
				setTotalFrames(tileCount);
				setCacheSliderValue(tileCount);
				setSequenceFrameAtIndex(0, 0, tileCount);
			}

			return () => {
				viewer && viewer.destroy();
			};
		}
	}, [tileSources]);

	useEffect(() => {
		isCaching && precacheData(cacheSliderValue);
	}, [isCaching]);

	useEffect(() => {
		leftPress && prevFrame();
	}, [leftPress]);

	useEffect(() => {
		rightPress && nextFrame();
	}, [rightPress]);

	const handleFPSChange = (event: any, newValue: SetStateAction<number>) => {
		setFPS(newValue);
	};

	const handleChange = (event: any, newSliderValue: any) => {
		setProgress(newSliderValue);
	};

	const handleChangeCommit = () => {
		if (progress !== commitSliderValue) {
			setSequenceFrameAtIndex(commitSliderValue, progress, totalFrames);
		}
		setCommitSliderValue(progress);
	};

	const prevFrame = () => {
		updateSequenceFrame(index === 0 ? totalFrames - 1 : index - 1);
	};

	const nextFrame = () => {
		updateSequenceFrame(index === totalFrames - 1 ? 0 : index + 1);
	};

	const updateSequenceFrame = (newIndex: SetStateAction<number>) => {
		setSequenceFrameAtIndex(index, newIndex, totalFrames);
		setProgress(newIndex);
		setCommitSliderValue(newIndex);
	};

	const setSequenceFrameAtIndex = (
		oldIndex: number,
		newIndex: any,
		totalFrames: number
	) => {
		setIndex(newIndex);
		currentFrame.current = newIndex;
		changedFrame = true;
		let nextIndex = (newIndex + 1) % totalFrames;
		if (oldIndex !== newIndex) {
			// Make the prev image go away
			viewer.world.getItemAt(oldIndex).setOpacity(0);
			// Stop pre-loading it
			viewer.world.getItemAt(oldIndex).setPreload(false);
			// Reveal the new image
			viewer.world.getItemAt(newIndex).setOpacity(1);
			// Preload the next image
			viewer.world.getItemAt(nextIndex).setPreload(true);
		}
	};

	const startSequencePlayback = () => {
		const millisecondsPerFrame = 1000 / fps;

		setPlaybackIntervalId(
			// @ts-ignore
			setTimeout(function clicker() {
				const isLastFrame = currentFrame.current >= totalFrames - 1;
				if (isLastFrame) {
					stopSequencePlayback(isLastFrame);
					setIsCachingComplete(true);
				} else {
					document?.getElementById("next")?.click();
					setPlaybackIntervalId(
						// @ts-ignore
						setTimeout(clicker, millisecondsPerFrame)
					);
				}
			}, millisecondsPerFrame)
		);
	};

	const stopSequencePlayback = (resetFrameIndex: boolean | undefined) => {
		clearTimeout(playbackIntervalId);

		if (resetFrameIndex) {
			currentFrame.current = 0;
			setIsPlaybackEnabled(false);
			document?.getElementById("play-pause")?.click();
		}
	};

	const precacheData = (cacheAmnt: number) => {
		let cacheIndex = progress;
		let item = null;
		let lastItem = null;
		for (let i = 0; i < cacheAmnt; i++) {
			cacheIndex = (cacheIndex + 1) % totalFrames;
			item = viewer.world.getItemAt(cacheIndex);
			if (!item._fullyLoaded) {
				item.setPreload(true);
				lastItem = item;
				item.addOnceHandler(
					"fully-loaded-change",
					(event: {
						eventSource: { setPreload: (arg0: boolean) => void };
					}) => {
						event.eventSource.setPreload(false);
					}
				);
			}
		}
		if (lastItem === null) {
			setIsCaching(false);
		} else {
			lastItem.addOnceHandler("fully-loaded-change", () => {
				setIsCaching(false);
				setIsCachingComplete(true);
			});
		}
	};

	const handlePrecacheChange = (
		event: any,
		newValue: SetStateAction<number>
	) => {
		setCacheSliderValue(newValue);
	};

	const startPrecache = () => {
		setIsCaching(true);
	};

	const cancelPrecache = () => {
		const world = viewer.world;
		for (let item in world._items) {
			world.getItemAt(item).setPreload(false);
		}
		setIsCaching(false);
	};

	const togglePlayback = () => {
		setIsPlaybackEnabled(!isPlaybackEnabled);
		if (isPlaybackEnabled) {
			startSequencePlayback();
		} else {
			stopSequencePlayback(false);
		}
	};

	return (
		<>
			<Slider
				className="progress-bar playback"
				defaultValue={1}
				aria-labelledby="discrete-slider"
				valueLabelDisplay="auto"
				step={1}
				min={1}
				max={totalFrames}
				value={progress + 1}
				onChange={handleChange}
				onChangeCommitted={handleChangeCommit}
			/>

			<div className="input-group">
				<SpeedControl handleFPSChange={handleFPSChange} fps={fps} />

				<Tooltip title="Previous Frame">
					<button
						className="icon-button icon--white"
						type="button"
						aria-label="previous"
						id="previous"
						onClick={prevFrame}
					>
						<SkipPreviousIcon />
					</button>
				</Tooltip>

				<Tooltip title={isPlaybackEnabled ? "Play" : "Pause"}>
					<button
						className="icon-button icon--white"
						type="button"
						aria-label="play"
						id="play-pause"
						onClick={togglePlayback}
					>
						{isPlaybackEnabled ? <PlayArrowIcon /> : <PauseIcon />}
					</button>
				</Tooltip>

				<Tooltip title="Next Frame">
					<button
						className="icon-button icon--white"
						type="button"
						aria-label="next"
						id="next"
						onClick={nextFrame}
					>
						<SkipNextIcon />
					</button>
				</Tooltip>

				<Tooltip title="Precache Images">
					<button
						className="icon-button icon--white"
						type="button"
						aria-label="precache"
						id="precache"
						onClick={() => setIsModalOpen(true)}
					>
						<GetAppIcon />
					</button>
				</Tooltip>
			</div>

			<PrecacheModal
				open={isModalOpen}
				modalClose={() => setIsModalOpen(false)}
				cacheSliderValue={cacheSliderValue}
				handlePrecacheSliderChange={handlePrecacheChange}
				totalFrames={totalFrames}
				isCaching={isCaching}
				isCachingComplete={isCachingComplete}
				startPrecache={startPrecache}
				cancelPrecache={cancelPrecache}
			/>
		</>
	);
};

export default SequenceControlDock;
