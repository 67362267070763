import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { searchClient, indexName as imageIndex } from "../../configs/algolia";

import CloseIcon from "@material-ui/icons/CloseOutlined";
import SearchIcon from "@material-ui/icons/SearchOutlined";

import {
	InstantSearch,
	SearchBox,
	MenuSelect,
	SortBy,
	RefinementList,
	Configure,
	createInfiniteHitsSessionStorageCache
} from "react-instantsearch-dom";

import InfiniteHits from "./components/InfiniteHits";

const Gallery: React.FC = () => {
	const { client, isAuthorized, isAdmin, isClient } = useSelector(
		(state: any) => state.account.account
	);
	const [sortBy, setSortBy] = useState("");
	const [filterBy, setFilterBy] = useState("");
	const searchState: any = sessionStorage.getItem("ais.infiniteHits");

	useEffect(() => {
		const state = JSON.parse(searchState)?.state;

		const getCurrentSort = () => {
			const sortedImageIndex = state?.index ?? imageIndex;
			return sortedImageIndex;
		};

		const getCurrentCategory = () => {
			const category = state?.disjunctiveFacetsRefinements?.categories ?? "";
			return category;
		};

		setSortBy(getCurrentSort());
		setFilterBy(getCurrentCategory());
	}, [searchState]);

	const filterImagesByClient = () => {
		// Whether an image is public or private is determined by the clientName metadata field.
		// e.g. A private image would use a clientName of "Ramona" or "ABT".
		// Public images use a clientName of “public”.
		const clientName = isAuthorized && isClient ? client : "public";

		return (
			<RefinementList
				attribute="clientName"
				defaultRefinement={[clientName]}
				className="hidden"
			/>
		);
	};

	return (
		<InstantSearch searchClient={searchClient} indexName={imageIndex}>
			<section className="gallery">
				<div className="gallery__header">
					<h1>MCAM Gallery</h1>
					<p>
						Ramona’s Multi-Camera Array Microscope (MCAM)
						synchronously captures high resolution across a
						large-field of view to produce gigapixel images and
						video. Free yourself from the constraints of traditional
						microscopy and see what you’ve been missing.
					</p>
				</div>

				<div className="gallery__search">
					<SearchBox
						searchAsYouType={false}
						showLoadingIndicator={true}
						submit={
							<>
								<SearchIcon className="search-icon" />
								<span className="search-text">Search</span>
							</>
						}
						reset={<CloseIcon />}
					/>

					<div className="gallery__search__refinements">
						<SortBy
							defaultRefinement={sortBy}
							items={[
								{
									value: imageIndex,
									label: "Sort by: Featured"
								},
								{
									value: `${imageIndex}_uploadDate_desc`,
									label: "Sort by: Date (Newest)"
								},
								{
									value: `${imageIndex}_uploadDate_asc`,
									label: "Sort by: Date (Oldest)"
								},
								{
									value: `${imageIndex}_displayTitle_asc`,
									label: "Sort by: Title (A-Z)"
								},
								{
									value: `${imageIndex}_displayTitle_desc`,
									label: "Sort by: Title (Z-A)"
								}
							]}
						/>
						<MenuSelect
							attribute="categories"
							defaultRefinement={filterBy}
							limit={100}
							transformItems={items =>
								items.map(item => ({
									...item,
									label: item.label
								}))
							}
							translations={{
								seeAllOption: "All"
							}}
						/>
					</div>
				</div>

				<Configure hitsPerPage={12} />
				<InfiniteHits cache={createInfiniteHitsSessionStorageCache()} />
				{!isAdmin && filterImagesByClient()}
			</section>
		</InstantSearch>
	);
};

export default Gallery;
