const OpenInFullOutlined = () => {
	return (
		<svg
			className="MuiSvgIcon-root"
			focusable="false"
			viewBox="0 0 24 24"
			aria-hidden="true"
		>
			<rect fill="none" height="24" width="24" />
			<polygon points="21,11 21,3 13,3 16.29,6.29 6.29,16.29 3,13 3,21 11,21 7.71,17.71 17.71,7.71" />
		</svg>
	);
};

export default OpenInFullOutlined;
