import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import Constants from '../common/constants';
import ApplicationUser from '../models/applicationUser';

export default class AuthService {
    static instance: any;

    constructor() {
        if (AuthService.instance) {
            return AuthService.instance
        }

        AuthService.instance = this;
        return AuthService.instance;
    }

    async getApplicationUser(): Promise<ApplicationUser> {
        let retVal = new ApplicationUser();

        try {
            var idToken = await this.getIdToken();
            if (idToken) {
                var decodedToken = idToken.decodePayload();

                if (decodedToken) {
                    retVal.isAuthorized = true;
                    var groups = decodedToken[Constants.CognitoGroupsClaimKey];
                    if (groups) {
                        if (groups.includes(Constants.AdminGroup)) {
                            retVal.isAdmin = true;
                        } 
                        if (groups.includes(Constants.ClientGroup)){
                            retVal.isClient = true;
                        }
                    }
                    var name = decodedToken[Constants.NameClaimKey];
                    if (name) {
                        retVal.name = name;
                    }
                    var clientBucketPrefix = decodedToken[Constants.ClientNameClaimKey];
                    if (clientBucketPrefix) {
                        retVal.client = clientBucketPrefix.toLowerCase();
                    }
                    var email = decodedToken[Constants.EmailClaimKey];
                    if (email) {
                        retVal.email = email;
                    }
                }
            }
        }
        catch (e: any) {

        }

        return retVal;
    }

    async isAuthorized() {
        try {
            var session = await Auth.currentSession();
            if (session.isValid()) {
                return true;
            }
        }
        catch (e: any) {
        }
        return false;
    }

    async getClaim(claim: string): Promise<string | null> {
        var idToken = await this.getIdToken();

        if (idToken) {
            var decodedToken = idToken.decodePayload();
            if (decodedToken && decodedToken[claim]) {
                return decodedToken[claim];
            }
        }

        return null;
    }

    async getJwt(): Promise<string | null> {
        var idToken = await this.getIdToken();

        if (idToken) {
            return idToken.getJwtToken();
        }

        return null;
    }

    async getIdToken(): Promise<CognitoIdToken | null> {
        try  {
            var userSession = await Auth.currentSession();

            if (userSession) {
                return userSession.getIdToken();
            }
        }
        catch (e:any){

        }

        return null;
    }

    async signIn(username:string, password:string): Promise<ApplicationUser> {
        let retVal:ApplicationUser = new ApplicationUser();

        try {
            await Auth.signIn(username, password);
            retVal = await this.getApplicationUser();
        }
        catch (e:any) {

        }
        return retVal;
    }

    async signOut(): Promise<ApplicationUser> {
        await Auth.signOut();
        return new ApplicationUser();
    }
}
