export default class Constants {
    public static ThumbnailKeySuffix: string = '/9/0_0.jpeg';
    public static NewPasswordRequired: string = 'NEW_PASSWORD_REQUIRED';
    public static SuccessResult: string = "SUCCESS";
    public static ClientNameUserAttributeKey: string = "custom:client_name";
    public static DziFileExtension: string = ".dzi";
    public static AdminGroup: string = "Admin";
    public static ClientGroup: string = "Client";
    public static CognitoGroupsClaimKey = "cognito:groups";
    public static ClientNameClaimKey = "custom:client_name";
    public static EmailClaimKey = "email";
    public static NameClaimKey = "name";
    public static JsonFileExtension = ".json";
}