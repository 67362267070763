import React from "react";

const MainNav: React.FC = () => {
	return (
		<>
			<li>
				<a href="https://www.ramonaoptics.com/about">
					About
				</a>
			</li>
			<li>
				<a href="https://medium.com/ramonaoptics" target="_blank">
					News
				</a>
			</li>
		</>
	);
};

export default MainNav;