import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { selectApplicationUser, signIn } from "../utils/accountSlice";
import ApplicationUser from "../models/applicationUser";
import { AppDispatch } from "../app/store";
import { useInput } from "../utils/forms";

import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import logo from "../assets/logo/icon/green/logo.svg";

const Login: React.FC = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	const history = useHistory();

	const { value: username, bind: bindUsername } = useInput("");
	const { value: password, bind: bindPassword } = useInput("");

	const dispatch: AppDispatch = useDispatch();
	let applicationUser: ApplicationUser = useSelector(selectApplicationUser);

	const handleSubmit = async (e: React.SyntheticEvent<Element, Event>) => {
		e.preventDefault();
		setLoading(true);

		try {
			const args: { username: string; password: string } = {
				username: username,
				password: password
			};

			applicationUser = await dispatch(signIn(args)).unwrap();
			!applicationUser && setError("Invalid username or password. Please try again.");
			applicationUser.isAuthorized
				? history.push("/")
				: setError("Invalid username or password. Please try again.");
		} catch (error: any) {
			setError("Unable to authenticate.");
		} finally {
			setLoading(false);
		}
	};

	return (
		<section className="login">
			<form className="login__form" onSubmit={handleSubmit}>
				<h1 className="login__heading">
					<img src={logo} alt="ramona logo" />
					Login
				</h1>

				{error && (
					<Alert severity="error" variant="filled">
						{error}
					</Alert>
				)}

				<div className="login__username">
					<label htmlFor="username">Username</label>
					<input
						id="username"
						type="text"
						placeholder="Enter username"
						autoComplete="off"
						autoFocus
						{...bindUsername}
					/>
				</div>

				<div className="login__password">
					<label htmlFor="password">Password</label>
					<input
						type="password"
						{...bindPassword}
						placeholder="Enter password"
					/>
				</div>

				<button
					className="login__submit button--primary button--large"
					type="submit"
					disabled={loading}
				>
					{loading && <CircularProgress />}
					Login
				</button>
			</form>
		</section>
	);
};

export default Login;
