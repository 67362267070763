import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoadingSpinner: React.FC = () => {
	return (
		<div className="loading-spinner">
			<CircularProgress color="inherit" variant="indeterminate" />
		</div>
	);
};

export default LoadingSpinner;
