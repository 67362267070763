import { configureStore, ThunkAction, Action, combineReducers, Middleware } from '@reduxjs/toolkit';
import accountReducer from '../utils/accountSlice';
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import ApplicationUser from '../models/applicationUser';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
});

const untypedMiddleware: any = Object as Middleware<(action: Action<'changePassword'>) => Promise<ApplicationUser>, RootState>;

customizedMiddleware.concat(untypedMiddleware);

const reducer = combineReducers({
  account: accountReducer
})

export const store = configureStore({
  reducer,
  middleware: customizedMiddleware
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = any> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
