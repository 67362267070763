const BottomCurve = () => {
	return (
		<svg
			className="bottom-curve"
			aria-hidden="true"
			viewBox="0 0 1440 113"
			fill="none"
			// xmlnsbx="https://boxy-svg.com"
		>
			<path
				fill="white"
				d="M 0.084 110.932 C 134.079 2.91 282.697 0.07500000000000002 330 0 C 320.409 -0.07300000000000002 0 0 0 0 L 0 111.875"
				// bxorigin="0.499397 0.5"
			></path>
			<g
				clipPath="url(#clip0_540:600)"
				transform="matrix(1, 0, 0, 1, 1, 0)"
			>
				<mask
					id="mask0_540:600"
					style={{ maskType: "alpha" }}
					maskUnits="userSpaceOnUse"
					x="-214"
					y="0"
					width="1662"
					height="1068"
				>
					<path
						d="M-214 534C-214 828.92 24.9644 1068 319.742 1068H1448V0H319.742C24.9644 0 -214 239.08 -214 534Z"
						fill="#C4C4C4"
					></path>
				</mask>
				<g mask="url(#mask0_540:600)">
					<path
						d="M319.742 1066C26.0699 1066 -212 827.816 -212 534C-212 240.184 26.0699 2 319.742 2H1446V1066H319.742Z"
						stroke="white"
						strokeWidth="4"
					></path>
					<path
						d="M319.742 1066C26.0699 1066 -212 827.816 -212 534C-212 240.184 26.0699 2 319.742 2H1446V1066H319.742Z"
						stroke="url(#paint0_linear_540:600)"
						strokeWidth="4"
					></path>
				</g>
			</g>
			<defs>
				<pattern
					id="pattern0"
					patternContentUnits="objectBoundingBox"
					width="1"
					height="1"
				>
					<use
						transform="matrix(0.00024418000248260796, 0, 0, 0.0004496400651987642, 0, 0)"
						// xlink:href="#image0_540:600"
					></use>
				</pattern>
				<linearGradient
					id="paint0_linear_540:600"
					x1="4.00003"
					y1="44"
					x2="1448"
					y2="44"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.2313" stopColor="#20CC4B"></stop>
					<stop
						offset="0.9224"
						stopColor="#20CC4B"
						stopOpacity="0"
					></stop>
				</linearGradient>
				<clipPath id="clip0_540:600">
					<rect width="1440" height="782" fill="white"></rect>
				</clipPath>
			</defs>
			<path
				style={{
					fill: "rgb(216, 216, 216)",
					stroke: "rgb(0, 0, 0)"
				}}
				d="M 271.74 457.55"
			></path>
		</svg>
	);
};

export default BottomCurve;