const LinkedIn = () => {
	return (
		<svg viewBox="0 0 24 24" fill="none">
			<path
				d="M23.815 0.185673C23.6966 0.0664733 23.5363 -0.000308534 23.3693 1.07173e-06H6.43526C4.73328 0.00136521 3.09999 0.679398 1.88755 1.88792C1.29119 2.48199 0.817291 3.18989 0.493282 3.97061C0.169274 4.75133 0.00160429 5.58935 0 6.43609V23.3696C0.000328618 23.5371 0.0663265 23.6976 0.183503 23.8159C0.302767 23.9335 0.462626 23.9996 0.629197 24H17.5632C19.2659 24.0001 20.9002 23.3219 22.1124 22.1121C22.7086 21.5179 23.1824 20.8099 23.5064 20.0293C23.8304 19.2486 23.9982 18.4106 24 17.5639V0.630345C24 0.547481 23.9837 0.465447 23.9519 0.389077C23.9201 0.312707 23.8736 0.24355 23.815 0.185673ZM8.64822 18.1303H6.13147V9.93888H8.64822V18.1303ZM7.38831 8.82018C7.00171 8.82002 6.63097 8.66468 6.35746 8.38825C6.08395 8.11181 5.93001 7.73687 5.92944 7.34572C5.92975 6.9545 6.0836 6.57941 6.35717 6.30291C6.63073 6.02642 7.00163 5.87117 7.38831 5.87128C7.77458 5.87202 8.14482 6.0276 8.41796 6.30395C8.6911 6.5803 8.84487 6.9549 8.84561 7.34572C8.84589 7.53943 8.80839 7.73129 8.73525 7.91031C8.66211 8.08933 8.55478 8.25199 8.4194 8.38896C8.28402 8.52593 8.12325 8.63453 7.94632 8.70853C7.76938 8.78252 7.57975 8.82046 7.3883 8.82018H7.38831ZM18.069 18.1303H15.5554V14.1454C15.5554 13.1952 15.5369 11.9735 14.2492 11.9735C12.9384 11.9735 12.7379 13.0079 12.7379 14.0767V18.1303H10.2243V9.93888H12.6361V11.0576H12.6701C13.0062 10.4148 13.8282 9.73605 15.0511 9.73605C17.5956 9.73605 18.069 11.4336 18.069 13.6398V18.1303Z"
				fill="#060F24"
			></path>
		</svg>
	);
};

export default LinkedIn;
