import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExpandLessIcon from "@material-ui/icons/ExpandLessOutlined";

import Colorbar from "./Colorbar";
import Tooltip from "./Tooltip";

interface Props {
	displayTitle: string;
	description: string;
	additionalDescription: string;
	categories: any;
	imageDetails: any;
	showColorBar: boolean;
}

interface LocationState {
	fromGallery: boolean;
}

const Metadata: React.FC<Props> = ({
	displayTitle,
	description,
	additionalDescription,
	categories,
	imageDetails,
	showColorBar
}) => {
	const history = useHistory();
	const { state } = useLocation<LocationState>();

	const [expandPanel, setExpandPanel] = useState(false);

	// Ensure that the image details are an array so we can map over them
	const details = imageDetails?.length ? imageDetails : [imageDetails];

	const buildDetailBody = (image: any) => {
		return (
			<>
				<div className="panel__details__acquisition-info">
					{image.acquisitionOrg && (
						<h3 className="panel__details__heading">
							{image.acquisitionOrg}
						</h3>
					)}
					{image.acquisitionDate && (
						<p>{formatDate(image.acquisitionDate)}</p>
					)}
				</div>
				{image.illumination && <p>{image.illumination}</p>}
			</>
		);
	};

	const formatDate = (date: string) => {
		return new Date(date).toLocaleDateString("en-US", {
			year: "numeric",
			month: "long",
			day: "numeric"
		});
	};

	const goBackToGallery = () => {
		// Use history.goBack if possible so that the gallery scroll position is retained
		const fromGallery = state && state.fromGallery;
		fromGallery ? history.goBack() : history.push("/gallery");
	};

	return (
		<div className="metadata">
			<div className="metadata__summary">
				<Tooltip title="Back To Gallery" placement="right">
					<button
						className="icon-button icon--white back-to-gallery"
						onClick={() => goBackToGallery()}
					>
						<ArrowBackIcon />
					</button>
				</Tooltip>

				<div
					className="metadata__title"
					onClick={() => setExpandPanel(!expandPanel)}
				>
					<span>
						<h1>{displayTitle}</h1>
						<h4>{expandPanel ? "Hide Details" : "Show Details"}</h4>
					</span>

					<button
						className={`toggle-details ${
							expandPanel ? "" : "expanded"
						}`}
					>
						<ExpandLessIcon />
					</button>
				</div>
			</div>

			<div className="metadata__details">
				<div className={`panel ${expandPanel ? "expanded" : ""}`}>
					<div className="panel__body">
						{details.map((image: any, index: number) => (
							<div className="panel__details" key={index}>
								<>
									{details.length > 1 && (
										<h2 className="panel__details__heading">
											{index === 0
												? "Left Side"
												: "Right Side"}
										</h2>
									)}

									{image && buildDetailBody(image)}
								</>
							</div>
						))}

						<div className="panel__description">
							{description && (
								<p>
									<em>{description}</em>
								</p>
							)}
							{additionalDescription && (
								<p>
									<em>{additionalDescription}</em>
								</p>
							)}

							{categories && (
								<p>
									<span>
										<strong>Categories: </strong>
										{categories.map(
											(category: any, index: number) =>
												category +
												(index < categories.length - 1
													? ", "
													: "")
										)}
									</span>
								</p>
							)}
						</div>
					</div>
				</div>
			</div>

			{showColorBar && <Colorbar />}
		</div>
	);
};

export default Metadata;
