import React from "react";
import { NavLink } from "react-router-dom";

const MainNav: React.FC = () => {
	return (
		<>
			<li>
				<NavLink to="/gallery" exact>
					MCAM Gallery
				</NavLink>
			</li>

			<li>
				<a href="https://www.ramonaoptics.com/products">
					Products
				</a>
			</li>

			<li>
				<a href="https://www.ramonaoptics.com/contact">
					Contact
				</a>
			</li>
		</>
	);
};

export default MainNav;