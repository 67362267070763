import { useEffect } from "react";
import { connectInfiniteHits } from "react-instantsearch-dom";
import GalleryItem from "./GalleryItem";

interface Props {
	hits: any;
	hasMore: boolean;
	refineNext: () => void;
	cache: () => void;
}

const InfiniteHits = ({ hits, hasMore, refineNext }: Props): JSX.Element => {
	let sentinel: any = null;

	const onSentinelIntersection = (entries: IntersectionObserverEntry[]) => {
		entries.forEach((entry: IntersectionObserverEntry) => {
			if (entry.isIntersecting && hasMore) {
				refineNext();
			}
		});
	};

	useEffect(() => {
		const observer = new IntersectionObserver(onSentinelIntersection);
		observer.observe(sentinel);
		return () => {
			observer.disconnect();
		};
	});

	return (
		<div className="ais-InfiniteHits">
			<ul className="ais-InfiniteHits-list">
				{hits.map(
					(hit: any) =>
						hit.tileSources.length > 0 && (
							<li
								key={hit.objectID}
								className="ais-InfiniteHits-item"
							>
								<GalleryItem hit={hit} />
							</li>
						)
				)}
				<li
					className="ais-InfiniteHits-sentinel"
					ref={c => (sentinel = c)}
				/>
			</ul>
		</div>
	);
};

export default connectInfiniteHits(InfiniteHits);
