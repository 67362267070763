import AWS from 'aws-sdk';
import S3 from 'aws-sdk/clients/s3';
import { CONFIG } from '../configs/config';
import AuthService from './authService';

export default class MediaService {
    authService: AuthService;
    authInitialized: boolean = false;

    static instance: MediaService;

    constructor() {
        this.authService = new AuthService();

        if (MediaService.instance) {
            return MediaService.instance
        }

        MediaService.instance = this;
        return MediaService.instance;
    }

    async getSignedUrl(key: string, bucket: string): Promise<string | null> {
        await this.setupAuth();
        const client = await this.getS3Client(bucket);
        if (client) {
            const signedUrl = await client.getSignedUrlPromise("getObject", { Key: key, Bucket: bucket });
            if (signedUrl) {
                return signedUrl;
            }
        }

        return null;
    }

    async setupAuth() {
        if (!this.authInitialized) {
            const jwt = await this.authService.getJwt();

            if (jwt) {
                AWS.config.region = CONFIG.REGION; // Region

                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: CONFIG.IDENTITY_POOL_ID,
                    Logins: { [CONFIG.IDENTITY_PROVIDER_NAME]: jwt }
                });

                this.authInitialized = true;
            }
        }
    }

    async getS3Client(bucket?: string): Promise<S3 | null> {
        if (!bucket) {
            bucket = CONFIG.S3_BUCKET_PUBLIC;
        }

        if (bucket === CONFIG.S3_BUCKET_PRIVATE) {
            await this.setupAuth();
        } else if (bucket === CONFIG.S3_BUCKET_PUBLIC) {
            AWS.config.region = CONFIG.REGION; // Region
        }

        var s3 = new AWS.S3({
            apiVersion: CONFIG.S3_API_VERSION,
            params: { Bucket: bucket }
        });

        return s3;
    }

    clearAuth() {
        AWS.config.region = CONFIG.REGION; // Region

        AWS.config.credentials = null;

        this.authInitialized = false;
    }
}