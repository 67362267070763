import React, { useState } from "react";
import { Popover, Slider, Tooltip as MuiTooltip, Tooltip } from "@material-ui/core";
import SpeedIcon from "@material-ui/icons/SpeedOutlined";

interface Props {
	handleFPSChange: (event: any, newValue: any) => void;
	fps: number;
}

const SpeedControl: React.FC<Props> = ({ handleFPSChange, fps }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handlePopoverClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const isPopoverOpen = Boolean(anchorEl);
	const popoverId = isPopoverOpen ? "playback-speed-popover" : undefined;

	return (
		<>
			<Popover
				className="popover"
				id={popoverId}
				open={isPopoverOpen}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				container={() => document.getElementById("viewer-container")}
			>
				<div className="vertical-slider">
					<h3>
						<span>{fps}</span>
						<small>FPS</small>
					</h3>
					<Slider
						className="progress-bar playback-speed-slider"
						valueLabelDisplay="auto"
						defaultValue={2}
						value={fps}
						min={0.5}
						step={0.5}
						max={5}
						orientation="vertical"
						onChange={handleFPSChange}
					/>
				</div>
			</Popover>

			<Tooltip title="Playback Speed">
				<button
					aria-describedby={popoverId}
					onClick={handlePopoverClick}
					className="icon-button icon--white"
				>
					<SpeedIcon />
				</button>
			</Tooltip>
		</>
	);
};

export default SpeedControl;
