import React from "react";

import AuthNav from "./Header/AuthNav";
import PrimaryLinks from "./Header/PrimaryLinks";
import SecondaryLinks from "./Header/SecondaryLinks";

import LinkedInIcon from "../../assets/icons/LinkedIn";
import TwitterIcon from "../../assets/icons/Twitter";
import InstagramIcon from "../../assets/icons/Instagram";

const Sidebar: React.FC = () => {
	return (
		<div className="sidebar">
			<div className="sidebar__nav">
				<ul className="sidebar__nav__auth-nav">
					<AuthNav />
				</ul>
				<ul className="sidebar__nav__main-nav">
					<PrimaryLinks />
					<SecondaryLinks />
				</ul>
			</div>

			<ul className="social-links">
				<li>
					<a
						href="https://www.linkedin.com/company/ramona-optics/"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="LinkedIn"
					>
						<LinkedInIcon />
					</a>
				</li>
				<li>
					<a
						href="https://twitter.com/ramonaoptics"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Twitter"
					>
						<TwitterIcon />
					</a>
				</li>
				<li>
					<a
						href="https://www.instagram.com/ramonaoptics/?hl=en"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Instagram"
					>
						<InstagramIcon />
					</a>
				</li>
			</ul>
		</div>
	);
};

export default Sidebar;
