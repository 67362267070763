import { useState } from "react";

import { Popover } from "@material-ui/core";
import GradientIcon from "@material-ui/icons/GradientOutlined";
import BorderLeftIcon from "@material-ui/icons/BorderLeftOutlined";
import BorderRightIcon from "@material-ui/icons/BorderRightOutlined";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";

import Tooltip from "./Tooltip";
import colorBar from "../../../assets/images/color-bar.png";

const Colorbar: React.FC = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [expandColorbar, setExpandColorbar] = useState(true);
	const [position, setPosition] = useState("right");

	const changeDockPosition = (selectedPosition: string) => {
		setPosition(selectedPosition);
		setExpandColorbar(true);
	};

	const handlePopoverClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const classList = [
		"colorbar",
		position,
		expandColorbar ? "show" : "hide"
	].join(" ");

	const isPopoverOpen = Boolean(anchorEl);
	const popoverId = isPopoverOpen ? "colorbar-popover" : undefined;

	return (
		<>
			<div className="colorbar__popover-container">
				<Tooltip title="Color Bar Options" placement="left">
					<button
						aria-describedby={popoverId}
						onClick={handlePopoverClick}
						className="icon-button icon--white"
					>
						<GradientIcon />
					</button>
				</Tooltip>

				<Popover
					id={popoverId}
					open={isPopoverOpen}
					anchorEl={anchorEl}
					onClose={handlePopoverClose}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right"
					}}
					transformOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					container={() =>
						document.getElementById("viewer-container")
					}
				>
					<ul className="colorbar__popover-menu">
						<li>
							<Tooltip title="Dock Right" placement="left">
								<button
									className={`icon-button ${
										position === "right" && expandColorbar
											? "icon--primary"
											: "icon--dark"
									}`}
									onClick={() => changeDockPosition("right")}
								>
									<BorderRightIcon />
								</button>
							</Tooltip>
						</li>

						<li>
							<Tooltip title="Dock Left" placement="left">
								<button
									className={`icon-button ${
										position === "left" && expandColorbar
											? "icon--primary"
											: "icon--dark"
									}`}
									onClick={() => changeDockPosition("left")}
								>
									<BorderLeftIcon />
								</button>
							</Tooltip>
						</li>

						<hr />

						<li>
							<Tooltip title="Show / Hide" placement="left">
								<button
									className={`icon-button ${
										expandColorbar
											? "icon--primary"
											: "icon--dark"
									}`}
									onClick={() =>
										setExpandColorbar(!expandColorbar)
									}
								>
									<VisibilityIcon />
								</button>
							</Tooltip>
						</li>
					</ul>
				</Popover>
			</div>

			<figure className={classList} aria-label="color bar">
				<img src={colorBar} alt="color bar" />
			</figure>
		</>
	);
};

export default Colorbar;
