import React from "react";
import { useLocation } from "react-router-dom";
import { useWindowHeight } from "@react-hook/window-size/";

import Header from "./Header";

const Layout: React.FC = props => {
	const location = useLocation();
	const windowHeight = useWindowHeight();

	const applyWindowHeight =
		location.pathname.includes("/signin") ||
		location.pathname.includes("/embed") ||
		location.pathname.includes("/viewer");
	const appHeight = applyWindowHeight ? windowHeight : "100%";

	const classList = () => {
		let classes = [];
		if (location.pathname.includes("/embed")) classes.push("embed");
		if (location.pathname.includes("/viewer")) classes.push("viewer-page");
		return classes.join(" ");
	};

	return (
		<div className={`app ${classList()}`} style={{ height: appHeight }}>
			<Header />
			<main className="main">
				<div className="page-wrapper">{props.children}</div>
			</main>
		</div>
	);
};

export default Layout;
