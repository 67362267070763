import React, { useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Toast } from "../../../utils/notifications";
import { AppDispatch } from "../../../app/store";
import ApplicationUser from "../../../models/applicationUser";
import { getApplicationUser, signOut } from "../../../utils/accountSlice";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const AuthNav: React.FC = () => {
	let history = useHistory();
	const dispatch: AppDispatch = useDispatch();
	let applicationUserState: ApplicationUser = useSelector(
		(state: any) => state.account.account
	);
	const authStatus = useSelector((state: any) => state.account.status);

	useEffect(() => {
		if (authStatus === "idle") {
			dispatch(getApplicationUser());
		}
	}, [dispatch, authStatus]);

	const handleLogout = async () => {
		try {
			dispatch(signOut()).then(res => {
				res && localStorage.clear();
			});
			Toast("Goodbye!", "Logged out successfully.", "success");
			history.push("/signin");
		} catch (error: any) {
			Toast("Error", error?.message ?? "", "danger");
		}
	};

	const getDisplayName = () => {
		if (applicationUserState.isAdmin) return "Admin";
		if (applicationUserState.isClient) return applicationUserState.client; 
	}

	const isLoggedIn =
		authStatus === "succeeded" &&
		applicationUserState &&
		applicationUserState.isAuthorized;

	return (
		<>
			{isLoggedIn ? (
				<>
					<li>
						<span className="client-name logout-text">
							{getDisplayName()}
						</span>
					</li>
					<li>
						<button
							className="logout-button"
							onClick={handleLogout}
						>
							Logout
							<ExitToAppIcon
								className="logout-button_icon"
								aria-label="log out"
							/>
						</button>
					</li>
				</>
			) : (
				<li>
					<NavLink to="/signin" exact>
						<span className="login-text">Login</span>
					</NavLink>
				</li>
			)}
		</>
	);
};

export default AuthNav;
