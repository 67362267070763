import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApplicationUser from "../models/applicationUser";
import AuthService from "../services/authService";
import MediaService from "../services/mediaService";

export interface AccountState {
	account: ApplicationUser;
	status: "idle" | "loading" | "failed" | "succeeded";
	error: any;
	authService: AuthService;
	mediaService: MediaService;
}

const initialState: AccountState = {
	account: new ApplicationUser(),
	status: "idle",
	error: null,
	authService: new AuthService(),
	mediaService: new MediaService()
};

export const getApplicationUser = createAsyncThunk(
	"account/getApplicationUser",
	async () => {
		const response = await initialState.authService.getApplicationUser();
		return response;
	}
);

export const signOut = createAsyncThunk("account/signout", async () => {
	const response = await initialState.authService.signOut();
	initialState.mediaService.clearAuth();
	return response;
});

export const signIn = createAsyncThunk(
	"account/signIn",
	async (args: { username: string; password: string }) => {
		const response = await initialState.authService.signIn(
			args.username,
			args.password
		);
		return response;
	}
);

export const account = createSlice({
	name: "account",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getApplicationUser.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(getApplicationUser.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.account = action.payload;
			})
			.addCase(getApplicationUser.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			})
			.addCase(signOut.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(signOut.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.account = action.payload;
			})
			.addCase(signOut.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			})
			.addCase(signIn.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.account = action.payload;
			})
			.addCase(signIn.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			});
	}
});

export const selectApplicationUser = (state: any) => state.account.account;

export default account.reducer;
