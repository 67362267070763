import React, { ReactElement } from "react";
import { Tooltip as MuiTooltip } from "@material-ui/core";

interface Props {
	title: string;
	placement?: any;
	enterTouchDelay?: number;
	arrow?: boolean;
	disablePortal?: boolean;
	container?: HTMLElement;
	children: ReactElement<any, any>;
}

const Tooltip: React.FC<Props> = ({
	title,
	placement = "top",
	enterTouchDelay = 0,
	arrow = false,
	disablePortal,
	container = document.getElementById("viewer-container"),
	children
}) => {
	const popperProps = disablePortal
		? {
				disablePortal: true
		  }
		: {
				container: container,
				modifiers: {
					preventOverflow: {
						boundariesElement: container
					}
				}
		  };

	return (
		<MuiTooltip
			title={title}
			placement={placement}
			enterTouchDelay={enterTouchDelay}
			arrow={arrow}
			PopperProps={popperProps}
		>
			{children}
		</MuiTooltip>
	);
};

export default Tooltip;
